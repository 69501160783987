import { Module } from 'vuex';
import { isDebugMode } from '@/config';
import api from '@/utils/api';
import { RootState, UserState, User } from '../types';

interface ScheduleData {
  working_days: string[];
  working_hours_start: string;
  working_hours_end: string;
  timezone: string;
}

interface RegisterData {
  email: string;
  password: string;
}

const userModule: Module<UserState, RootState> = {
  namespaced: true,
  
  state: () => ({
    user: null,
  }),

  getters: {
    user: (state: UserState): User | null => state.user,
  },

  mutations: {
    setUser(state: UserState, userData: User | null) {
      console.log('[User] Setting user data:', userData ? 'Present' : 'Null');
      if (userData) {
        state.user = {
          ...userData,
          total_system_points: userData.total_system_points || 0
        };
      } else {
        state.user = null;
      }
    },

    updateWorkingSchedule(state: UserState, scheduleData: ScheduleData) {
      console.log('[User] Updating working schedule');
      if (state.user) {
        state.user.working_days = scheduleData.working_days;
        state.user.working_hours_start = scheduleData.working_hours_start;
        state.user.working_hours_end = scheduleData.working_hours_end;
        state.user.timezone = scheduleData.timezone;
      }
    },
  },

  actions: {
    setUser({ commit }, userData: User | null) {
      console.log('[User] Action: setUser called with data:', userData ? 'Present' : 'Null');
      try {
        if (userData && (!userData.id || !userData.email)) {
          console.error('[User] Invalid user data received:', userData);
          throw new Error('Invalid user data format');
        }
        commit('setUser', userData);
        console.log('[User] User data set successfully');
        return true;
      } catch (error) {
        console.error('[User] Error in setUser action:', error);
        throw error;
      }
    },

    async registerWithEmail({ commit }, { email, password }: RegisterData) {
      try {
        console.log('[User] Registering with email');
        const response = await api.post<User>('/auth/register', { email, password });

        if (!response.data) {
          throw new Error('Registration failed');
        }

        commit('setUser', response.data);
        return response.data;
      } catch (error) {
        if (isDebugMode()) {
          console.error('[User] Registration error:', error);
        }
        throw error;
      }
    },

    async updateWorkingSchedule({ commit }, scheduleData: ScheduleData) {
      try {
        console.log('[User] Updating working schedule');
        const response = await api.put<boolean>('/users/schedule', scheduleData);

        if (!response.data) {
          throw new Error('Failed to update working schedule');
        }

        commit('updateWorkingSchedule', scheduleData);
        return true;
      } catch (error) {
        if (isDebugMode()) {
          console.error('[User] Schedule update error:', error);
        }
        throw error;
      }
    },
  }
};

export default userModule; 