import { isDebugMode } from '@/config';

import TickCircleIconPng from './tick-circle.png';
import ErrorIconPng from './error.png';
import InfoIconPng from './info.png';
import WarningIconPng from './warning.png';
import RewardIconPng from './points_md.png';
import BadgeIconPng from './badge.png';
import CloseIconPng from './close.png';

export const icons = {
  success: TickCircleIconPng,
  error: ErrorIconPng,
  info: InfoIconPng,
  warning: WarningIconPng,
  reward: RewardIconPng,
  badge: BadgeIconPng,
  close: CloseIconPng
};

if (isDebugMode()) {
  console.log('Icons loaded:', icons);
}

export function getIcon(name) {
  return icons[name] || null;
}
