import store from '@/store';
import { isDebugMode } from '@/config';

export const handleAuthError = async () => {
  if (isDebugMode()) {
    console.log('Auth error handler triggered');
  }
  
  // Only handle the logout, let router guard handle redirect
  await store.dispatch('auth/logout');
};