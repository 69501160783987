import { createStore } from 'vuex';
import auth from './modules/auth';
import user from './modules/user';
import google from './modules/google';
import notifications from './modules/notifications';
import { isDebugMode } from '@/config';

// Create store persistence plugin
const persistencePlugin = (store) => {
  // Load persisted state
  try {
    const savedState = localStorage.getItem('vuex');
    if (savedState) {
      const parsed = JSON.parse(savedState);
      if (parsed.user && parsed.user.user) {
        store.commit('user/setUser', parsed.user.user);
      }
      if (parsed.auth && parsed.auth.token) {
        store.commit('auth/setToken', parsed.auth.token);
        store.commit('auth/setAuthenticated', true);
      }
      if (parsed.google && parsed.google.isGoogleCalendarConnected) {
        store.commit('google/setGoogleCalendarConnected', parsed.google.isGoogleCalendarConnected);
      }
    }
  } catch (error) {
    console.error('[Store] Failed to load persisted state:', error);
    localStorage.removeItem('vuex');
    localStorage.removeItem('token');
  }

  store.subscribe((mutation, state) => {
    try {
      const stateToPersist = {
        auth: { 
          token: state.auth.token,
          isAuthenticated: state.auth.isAuthenticated
        },
        user: { user: state.user.user },
        google: { isGoogleCalendarConnected: state.google.isGoogleCalendarConnected }
      };
      localStorage.setItem('vuex', JSON.stringify(stateToPersist));
    } catch (error) {
      console.error('[Store] Failed to persist state:', error);
    }
  });
};

const store = createStore({
  modules: {
    auth,
    user,
    google,
    notifications
  },
  plugins: [persistencePlugin]
});

export default store; 