<template>
  <div
    class="confetti-container"
    aria-hidden="true"
  />
</template>

<script setup lang="ts">
import { onMounted, watch } from 'vue';
import { useConfetti } from '@/composables/useConfetti';

const { showConfetti } = useConfetti();

onMounted(() => {
  showConfetti('default');
});

watch(
  () => 'default' as const,
  (newType) => {
    showConfetti(newType);
  }
);
</script>

<style scoped>
.confetti-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
</style>
