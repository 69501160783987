<template>
  <div
    id="app"
    class="flex flex-col min-h-screen"
  >
    <NavigationBar
      :is-logged-in="isLoggedIn"
      :user="user"
      @login-email="loginWithEmail"
    />
    <div class="flex-grow pb-16">
      <template v-if="!isInitialized">
        <div 
          role="status" 
          class="flex flex-col items-center justify-center h-full p-4"
        >
          <div class="loading-placeholder mb-2">
            <span class="sr-only">Loading application...</span>
            Connecting to server...
          </div>
          <div v-if="error" 
               role="alert" 
               class="text-red-600 text-sm mt-2"
          >
            {{ error }}
          </div>
        </div>
      </template>
      <template v-else-if="error">
        <div 
          role="alert" 
          class="flex flex-col items-center justify-center h-full p-4"
        >
          <div class="text-red-600 mb-2">
            Unable to connect to server
          </div>
          <button 
            @click="retryInitialization"
            class="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          >
            Retry Connection
          </button>
        </div>
      </template>
      <template v-else>
        <router-view />
      </template>
    </div>
    <FooterBar />
    <ToastContainer />
  </div>
</template>

<script setup>
import { computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import NavigationBar from './components/NavigationBar.vue';
import FooterBar from './components/FooterBar.vue';
import { useToast } from '@/composables/useToast';
import { useTokenRefresh } from '@/composables/useTokenRefresh';
import { isDebugMode } from '@/config'; 
import ToastContainer from '@/components/ToastContainer.vue';

const store = useStore();
const router = useRouter();
const { showToast } = useToast();

const isLoggedIn = computed(() => store.getters['auth/isAuthenticated']);
const user = computed(() => store.getters['auth/currentUser']);
const isInitialized = computed(() => store.getters['auth/isInitialized']);
const error = computed(() => store.getters['auth/error']);

useTokenRefresh();

const initializeApp = async () => {
  try {
    await store.dispatch('auth/initialize');
  } catch (error) {
    if (isDebugMode()) {
      console.error('Error during auth initialization:', error);
    }
    showToast({
      type: 'error',
      message: 'Unable to connect to server. Please check your connection and try again.'
    });
  }
};

const retryInitialization = async () => {
  await initializeApp();
};

onMounted(async () => {
  if (isDebugMode()) {
    console.log('Router:', router);
    console.log('Store:', store);
  }
  await initializeApp();
});

const loginWithEmail = async (email, password) => {
  try {
    await store.dispatch('auth/loginWithEmail', { email, password });
  } catch (error) {
    if (isDebugMode()) {
      console.error('Login error:', error);
    }
    throw error;
  }
};
</script>

<style>
#app {
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
}

.loading-placeholder {
  @apply text-gray-600 text-center;
}
</style>