import { createApp, type App as VueApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/tailwind.css'
import { initializeAnalytics } from './utils/analytics'
import { isDebugMode } from './config'

interface RequiredEnvVars {
  VITE_APP_ENV: string | undefined
  VITE_DEBUG_MODE: string | undefined
  VITE_API_URL: string | undefined
}

if (import.meta.env.DEV || import.meta.env.VITE_APP_ENV === 'staging') {
  const requiredVars: RequiredEnvVars = {
    VITE_APP_ENV: import.meta.env.VITE_APP_ENV,
    VITE_DEBUG_MODE: import.meta.env.VITE_DEBUG_MODE,
    VITE_API_URL: import.meta.env.VITE_API_URL
  };

  console.log('Environment Variables:', requiredVars);
  
  const missingVars = Object.entries(requiredVars)
    .filter(([_, value]) => !value)
    .map(([key]) => key);

  if (missingVars.length > 0) {
    console.error('Missing required environment variables:', missingVars);
  }
}

const app: VueApp = createApp(App)

initializeAnalytics({
  requireCookieConsent: true
})

app.config.errorHandler = (error: unknown, vm: any, info: string) => {
  console.error('Global error:', error)
  console.error('Component:', vm)
  console.error('Error Info:', info)
  
  // Log additional startup information
  console.log('Environment:', import.meta.env)
  console.log('API URL:', import.meta.env.VITE_API_URL)
  console.log('Debug Mode:', isDebugMode())
}

if (import.meta.env.DEV || import.meta.env.VITE_APP_ENV === 'staging') {
  const requiredVars: RequiredEnvVars = {
    VITE_APP_ENV: import.meta.env.VITE_APP_ENV,
    VITE_DEBUG_MODE: import.meta.env.VITE_DEBUG_MODE,
    VITE_API_URL: import.meta.env.VITE_API_URL
  };

  console.log('Environment Variables:', requiredVars);
  
  const missingVars = Object.entries(requiredVars)
    .filter(([_, value]) => !value)
    .map(([key]) => key);

  if (missingVars.length > 0) {
    console.error('Missing required environment variables:', missingVars);
  }
}

console.log('Environment Debug:', {
  VITE_APP_ENV: import.meta.env.VITE_APP_ENV?.toLowerCase(),
  hostname: window.location.hostname,
  mode: import.meta.env.MODE,
  isDebug: isDebugMode()
});

app.use(router).use(store).mount('#app') 