import { Module } from 'vuex'
import { setToken, removeToken } from '@/utils/tokenStorage'
import { generateRandomString } from '@/utils/auth'
import { RootState, AuthState, User } from '../types'

const MAX_RETRIES = 3;
const RETRY_DELAY = 1000; // 1 second

interface LoginPayload {
  user: User
  token: string
}

interface GoogleLoginPayload {
  credential: string
  state: string
}

const authModule: Module<AuthState, RootState> = {
  namespaced: true,
  
  state: () => ({
    isAuthenticated: false,
    token: null,
    loading: false,
    initialized: false,
    error: null,
    apiVersion: null,
    currentUser: null
  }),

  getters: {
    isAuthenticated: (state: AuthState): boolean => state.isAuthenticated,
    token: (state: AuthState): string | null => state.token,
    isLoading: (state: AuthState): boolean => state.loading,
    isInitialized: (state: AuthState): boolean => state.initialized,
    error: (state: AuthState): string | null => state.error,
    apiVersion: (state: AuthState): string | null => state.apiVersion,
    currentUser: (state: AuthState): User | null => state.currentUser
  },

  mutations: {
    setToken(state: AuthState, token: string | null) {
      console.log('Token being set:', token ? 'exists' : 'null');
      state.token = token
      if (token) {
        setToken(token)
      } else {
        removeToken()
      }
    },

    setAuthenticated(state: AuthState, isAuthenticated: boolean) {
      console.log('Auth state changing to:', isAuthenticated);
      state.isAuthenticated = isAuthenticated
    },

    setLoading(state: AuthState, loading: boolean) {
      state.loading = loading
    },

    setInitialized(state: AuthState, initialized: boolean) {
      state.initialized = initialized
    },

    setError(state: AuthState, error: string | null) {
      state.error = error
    },

    setApiVersion(state: AuthState, apiVersion: string | null) {
      state.apiVersion = apiVersion
    },

    setCurrentUser(state: AuthState, user: User | null) {
      if (user) {
        console.log('[Auth Debug] setCurrentUser incoming value:', {
          original: user.list_profile_under_browse,
          type: typeof user.list_profile_under_browse
        });

        const normalizedUser = {
          ...user,
          list_profile_under_browse: user.list_profile_under_browse === true
        };

        console.log('[Auth Debug] setCurrentUser normalized value:', {
          final: normalizedUser.list_profile_under_browse,
          type: typeof normalizedUser.list_profile_under_browse
        });

        state.currentUser = normalizedUser;
      } else {
        state.currentUser = null;
      }
    }
  },

  actions: {
    async initialize({ commit, dispatch }) {
      console.log('[Auth Debug] Starting initialization...');
      try {
        commit('setLoading', true);
        const token = localStorage.getItem('token');
        
        if (!token) {
          console.log('[Auth Debug] No token found, completing initialization');
          commit('setInitialized', true);
          return;
        }

        const response = await fetch(`${import.meta.env.VITE_API_URL}/auth/me`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        
        if (!response.ok) {
          console.log('[Auth Debug] Auth/me failed, logging out');
          await dispatch('logout');
          return;
        }
        
        const userData = await response.json();
        
        console.log('[Auth Debug] Raw user data:', {
          value: userData.list_profile_under_browse,
          type: typeof userData.list_profile_under_browse,
          truthyTest: userData.list_profile_under_browse === true,
          allFields: Object.keys(userData)
        });

        const normalizedUserData = {
          ...userData,
          list_profile_under_browse: userData.list_profile_under_browse === true
        };
        
        console.log('[Auth Debug] Normalized user data:', {
          value: normalizedUserData.list_profile_under_browse,
          type: typeof normalizedUserData.list_profile_under_browse
        });
        
        commit('setToken', token);
        commit('setAuthenticated', true);
        commit('setCurrentUser', normalizedUserData);
        
      } catch (error) {
        console.error('Auth initialization error:', error);
        commit('setError', (error as Error).message || 'Initialization error');
        await dispatch('logout');
      } finally {
        commit('setInitialized', true);
        commit('setLoading', false);
      }
    },
    
    async login({ commit }, { user, token }: LoginPayload) {
      try {
        commit('setLoading', true)
        commit('setError', null)
        
        if (!token) {
          throw new Error('Login error: No token provided')
        }
        
        commit('setToken', token)
        commit('setAuthenticated', true)
        commit('setCurrentUser', user)
      } catch (error) {
        const errorMessage = error instanceof Error ? error.message : 'Login error'
        commit('setError', errorMessage)
        commit('setToken', null)
        commit('setAuthenticated', false)
        commit('setCurrentUser', null)
        throw new Error(errorMessage)
      } finally {
        commit('setLoading', false)
      }
    },

    async logout({ commit }) {
      try {
        commit('setLoading', true)
        commit('setError', null)
        
        // Clear token from localStorage
        localStorage.removeItem('token')
        
        commit('setToken', null)
        commit('setAuthenticated', false)
        commit('setCurrentUser', null)
      } catch (error) {
        const errorMessage = error instanceof Error ? error.message : 'Logout error'
        commit('setError', errorMessage)
        throw new Error(errorMessage)
      } finally {
        commit('setLoading', false)
      }
    },

    async createAuthState({ commit }): Promise<string> {
      try {
        const state = generateRandomString(32)
        localStorage.setItem('googleAuthState', state)
        return state
      } catch (error) {
        commit('setError', 'Failed to create auth state')
        throw new Error('Failed to create auth state')
      }
    },

    async googleLogin({ commit, dispatch }, { credential, state }: GoogleLoginPayload) {
      try {
        commit('setLoading', true)
        commit('setError', null)

        // Verify state matches
        const savedState = localStorage.getItem('googleAuthState')
        if (!savedState || savedState !== state) {
          throw new Error('Invalid authentication state')
        }

        // Clear the saved state
        localStorage.removeItem('googleAuthState')

        const response = await fetch(`${import.meta.env.VITE_API_URL}/auth/google`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ credential })
        })

        if (!response.ok) {
          throw new Error('Google authentication failed')
        }

        const { user, token } = await response.json()
        
        await dispatch('login', { user, token })
        return { success: true }
      } catch (error) {
        const errorMessage = error instanceof Error ? error.message : 'Google login failed'
        commit('setError', errorMessage)
        return { error: errorMessage }
      } finally {
        commit('setLoading', false)
      }
    }
  }
}

export default authModule 