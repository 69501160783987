import axios, { 
  AxiosInstance, 
  InternalAxiosRequestConfig,
  AxiosResponse, 
  AxiosError 
} from 'axios';
import { isDebugMode } from '@/config';
import store from '@/store';
import { API_URL } from '@/config';

const api: AxiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

api.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    const token = store.getters['auth/token'];
    if (token && config.headers) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    if (isDebugMode()) {
      console.log('API Request:', {
        url: config.url,
        method: config.method
      });
    }
    return config;
  },
  (error: AxiosError) => Promise.reject(error)
);

// Add more specific error types
export interface ValidationError {
  field: string;
  message: string;
}

export interface ApiError {
  message: string;
  code?: string;
  details?: any;
  validation_errors?: ValidationError[];
}

// Update the error interceptor with better error handling
api.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError<ApiError>) => {
    if (isDebugMode()) {
      console.error('API Error:', {
        url: error.config?.url,
        status: error.response?.status,
        data: error.response?.data
      });
    }

    // Handle different error scenarios
    if (error.response?.status === 422) {
      const validationErrors = error.response.data?.validation_errors;
      if (validationErrors) {
        error.message = validationErrors.map(err => `${err.field}: ${err.message}`).join(', ');
      } else {
        error.message = error.response.data?.message || 'Validation failed';
      }
    } else if (error.response?.status === 401) {
      // Handle unauthorized access
      store.dispatch('auth/logout');
    } else if (error.response?.status === 404) {
      error.message = 'Resource not found';
    }

    return Promise.reject(error);
  }
);

// Add return type for duplicateEmailTemplate
export interface EmailTemplateResponse {
  id: number;
  name: string;
  content: string;
  created_at: string;
}

export const duplicateEmailTemplate = async (templateId: number): Promise<EmailTemplateResponse> => {
  const response = await api.post<EmailTemplateResponse>(
    `/api/email-templates/${templateId}/duplicate`
  );
  return response.data;
}; 

// Add return type for getPublicServices
export interface PublicService {
  id: number;
  name: string;
  description: string;
  is_public: boolean;
}

export const getPublicServices = async (username: string): Promise<PublicService[]> => {
  const response = await api.get<PublicService[]>(`/s/${username}`);
  return response.data;
}; 

// Add back the default export that was accidentally removed
export default api;