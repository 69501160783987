import { createRouter, createWebHistory, RouteRecordRaw, RouteLocationNormalized, NavigationGuardNext } from 'vue-router';
import routes from './routes';
import store from '@/store';
import { trackPageView } from '@/utils/analytics';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: routes as RouteRecordRaw[]
});

// Updated navigation guard
router.beforeEach(async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  // Wait for auth to be initialized if it hasn't been
  if (!store.getters['auth/isInitialized']) {
    await store.dispatch('auth/initialize');
  }

  // Changed from isLoggedIn to isAuthenticated to match the store
  const isAuthenticated = store.getters['auth/isAuthenticated'];
  console.log('Router Guard - Auth State:', {
    isAuthenticated,
    path: to.fullPath,
    requiresAuth: to.matched.some(record => record.meta.requiresAuth),
    isAuthRoute: ['Login', 'Signup', 'ForgotPassword'].includes(to.name as string)
  });

  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const isAuthRoute = ['Login', 'Signup', 'ForgotPassword'].includes(to.name as string);

  if (requiresAuth && !isAuthenticated) {
    console.log('Redirecting to login - Auth required but not authenticated');
    next({ name: 'Login', query: { redirects: to.fullPath } });
    return;
  }

  if (isAuthenticated && isAuthRoute) {
    console.log('Redirecting to home - Already authenticated');
    next({ name: 'Home' });
    return;
  }

  next();
});

router.afterEach((to: RouteLocationNormalized) => {
  trackPageView(to.name?.toString() || to.path);
});

export default router; 