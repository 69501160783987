<template>
  <div
    :class="[
      'toast',
      customClass || `toast-${type}`,
      { 'badge-toast': isBadgeToast }
    ]"
    role="alert"
    aria-live="assertive"
    aria-atomic="true"
  >
    <ConfettiEffect
      v-if="type === 'reward' && showConfetti"
      :key="confettiKey"
    />
    <div class="toast-content">
      <img
        v-if="icon"
        :src="icon"
        :alt="iconLabel"
        class="toast-icon"
      >
      <div class="toast-message">
        {{ message }}
      </div>
      <div
        v-if="rewardPoints"
        class="toast-reward"
      >
        +{{ rewardPoints }} points
      </div>
      <div
        v-if="badgeInfo"
        class="toast-badge"
      >
        <div class="badge-content">
          <img
            :src="badgeInfo.imageUrl"
            :alt="badgeInfo.name"
            class="badge-image"
          >
          <div class="badge-info">
            <div class="badge-name">
              {{ badgeInfo.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <button
      v-if="showCloseButton"
      class="toast-close"
      aria-label="Close notification"
      @click="closeToast"
    >
      &times;
    </button>
  </div>
</template>

<script setup>
import { ref, onMounted, watch, computed } from 'vue';
import { isDebugMode } from '@/config';
import ConfettiEffect from './ConfettiEffect.vue';
import { icons } from '@/assets/icons';

const props = defineProps({
  message: {
    type: String,
    required: true
  },
  type: {
    type: String,
    default: 'success',
    validator: (value) => [
      'success', 
      'error', 
      'info', 
      'warning', 
      'reward', 
      'badge', 
      'custom',
      'delete-success'
    ].includes(value)
  },
  duration: {
    type: Number,
    default: 3000
  },
  showCloseButton: {
    type: Boolean,
    default: false
  },
  rewardPoints: {
    type: Number,
    default: null
  },
  badgeInfo: {
    type: Object,
    default: null
  },
  showConfetti: {
    type: Boolean,
    default: false
  },
  icon: {
    type: String,
    default: null
  },
  customClass: {
    type: String,
    default: ''
  }
});

const show = ref(false);
const timer = ref(null);

const iconLabel = computed(() => {
  switch (props.type) {
    case 'success': return 'Success';
    case 'error': return 'Error';
    case 'info': return 'Information';
    case 'warning': return 'Warning';
    case 'reward': return 'Reward';
    case 'badge': return 'Badge';
    default: return '';
  }
});

const closeToast = () => {
  show.value = false;
  if (timer.value) {
    clearTimeout(timer.value);
  }
  emit('close');
};

const confettiKey = computed(() => Date.now());

const isBadgeToast = computed(() => props.type === 'badge');

onMounted(() => {
  show.value = true;
  if (props.duration > 0) {
    timer.value = setTimeout(() => {
      show.value = false;
    }, props.duration);
  }
});

watch(() => props.duration, (newDuration) => {
  if (timer.value) {
    clearTimeout(timer.value);
  }
  if (newDuration > 0) {
    timer.value = setTimeout(() => {
      show.value = false;
    }, newDuration);
  }
});

if (isDebugMode()) {
  console.log('Toast notification mounted:', props);
}

const emit = defineEmits(['close']);
</script>

<style scoped>
.toast {
  position: relative;
  padding: 16px 20px;
  margin-bottom: 10px;
  color: white;
  font-weight: bold;
  border-radius: 4px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: auto;
}

.badge-toast {
  top: 0;
  width: auto;
  padding: 24px;
  margin-bottom: 0;
  font-weight: bold;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.toast-content {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.toast-icon {
  width: 24px;
  height: 24px;
  margin-right: 12px;
  filter: brightness(0) invert(1);
}

.toast-message {
  flex-grow: 1;
}

.toast-close {
  background: none;
  border: none;
  color: white;
  font-size: 1.2em;
  cursor: pointer;
  padding: 0 0 0 12px;
}

.toast-success { background-color: #4CAF50; }
.toast-error { background-color: #ff4800; }
.toast-info { background-color: #2196F3; }
.toast-warning { background-color: #FF9800; }
.toast-reward { background-color: #9C27B0; }
.toast-badge { background-color: #3e00c4; }
.toast-delete-success { background-color: #dc3545; }

.toast-badge {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 16px;
}

.badge-content {
  display: flex;
  align-items: center;
  width: 100%;
}

.badge-image {
  width: 120px;
  height: 120px;
  object-fit: contain;
  margin-right: 24px;
}

.badge-info {
  flex: 1;
}

.badge-name {
  font-weight: bold;
  font-size: 1.4em;
  margin-bottom: 8px;
}

.badge-message {
  font-size: 1.1em;
  line-height: 1.4;
}

@media (max-width: 767px) {
  .toast,
  .badge-toast {
    width: 100%;
  }
}
</style>
