import { AppRouteRecord } from '../types';

const serviceRoutes: AppRouteRecord[] = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('@/pages/HomePage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/services',
    name: 'Services',
    component: () => import('@/pages/ServicesPage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/email-templates',
    name: 'EmailTemplates',
    component: () => import('@/pages/EmailTemplatesPage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/forms',
    name: 'Forms',
    component: () => import('@/pages/FormsPage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/services/:id',
    name: 'ServiceDetails',
    component: () => import('@/pages/ServiceDetailsPage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/services/new',
    name: 'NewService',
    component: () => import('@/pages/NewServicePage.vue'),
    meta: { requiresAuth: true }
  }
];

export default serviceRoutes; 