import { AppRouteRecord } from '../types';

const settingRoutes: AppRouteRecord[] = [
  {
    path: '/settings',
    name: 'Settings',
    component: () => import('@/pages/SettingsPage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/settings/profile',
    name: 'Profile',
    component: () => import('@/pages/PublicProfilePage.vue'),
    meta: { requiresAuth: true }
  }
];

export default settingRoutes; 