/**
 * Token storage utility functions
 */

/**
 * Store the authentication token in localStorage
 * @param token - The authentication token to store
 */
export const setToken = (token: string): void => {
  localStorage.setItem('token', token);
};

/**
 * Retrieve the authentication token from localStorage
 * @returns The stored token or null if not found
 */
export const getToken = (): string | null => {
  return localStorage.getItem('token');
};

/**
 * Remove the authentication token from localStorage
 */
export const removeToken = (): void => {
  localStorage.removeItem('token');
}; 