<template>
  <footer class="bg-white shadow-md fixed bottom-0 left-0 right-0 z-50">
    <div class="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
      <div class="flex items-center justify-between py-0 rounded-md text-sm">
        <div class="flex items-center relative">
          <transition
            name="fade"
            mode="out-in"
          >
            <div
              v-if="!showFeedback || !isLoggedIn"
              :key="'default'"
              class="flex items-center"
            >
              <router-link
                v-if="isLoggedIn"
                to="/about"
                class="text-gray-600 hover:text-gray-900 px-2 py-2 font-medium min-h-[44px] min-w-[44px] flex items-center"
                aria-label="About"
              >
                About
              </router-link>
              <router-link
                to="/privacy-policy"
                class="text-gray-600 hover:text-gray-900 px-2 py-2 font-medium min-h-[44px] min-w-[44px] flex items-center"
                aria-label="Privacy Policy"
              >
                {{ isLoggedIn ? 'Privacy' : 'Privacy Policy' }}
              </router-link>
            </div>
            <div
              v-else
              :key="'feedback'"
            >
              <router-link
                to="/feedback"
                class="text-gray-600 hover:text-gray-900 px-2 py-2 font-medium animate-pulse min-h-[44px] min-w-[44px] flex items-center"
                aria-label="Give Feedback"
              >
                Give Feedback
              </router-link>
            </div>
          </transition>
        </div>
        <div class="flex items-center text-gray-600 text-sm px-2 py-2 min-h-[44px]">
          <span
            v-if="apiVersion"
            class="mr-1 text-gray-600"
          >{{ apiVersion }} alpha</span>
          © 
          <a 
            href="https://mindfulenterprise.com" 
            class="ml-1"
          >
            2023-{{ currentYear }}
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { computed, ref, onMounted, onUnmounted } from 'vue';
import { useStore } from 'vuex';
import { API_URL, isDebugMode } from '@/config'
import axios from 'axios';

export default {
  name: 'FooterBar',
  setup() {
    const store = useStore();
    const isLoggedIn = computed(() => store.getters.isLoggedIn);
    const showFeedback = ref(false);
    const apiVersion = ref(null);
    let intervalId = null;

    const fetchApiVersion = async () => {
      if (isDebugMode()) {
        console.log('Fetching API version from:', `${API_URL}/system/version`);
      }
      try {
        const response = await axios.get(`${API_URL}/system/version`);
        if (isDebugMode()) {
          console.log('API Version Response:', response.data);
        }
        if (response.data && response.data.version) {
          apiVersion.value = response.data.version;
          if (isDebugMode()) {
            console.log('Set API Version to:', apiVersion.value);
          }
        } else {
          throw new Error('Invalid version response');
        }
      } catch (error) {
        apiVersion.value = 'v1';
        if (isDebugMode()) {
          console.error('Error fetching API version:', error);
          console.log('Using fallback version:', apiVersion.value);
        }
      }
    };

    const toggleFeedback = () => {
      showFeedback.value = !showFeedback.value;
    };

    onMounted(() => {
      fetchApiVersion();
      intervalId = setInterval(toggleFeedback, 5000);
    });

    onUnmounted(() => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    });

    return {
      isLoggedIn,
      currentYear: new Date().getFullYear(),
      showFeedback,
      apiVersion
    };
  }
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

@keyframes pulse {
  0%, 100% {
    color: #4B5563; /* text-gray-600 */
  }
  50% {
    color: #3B82F6; /* bg-blue-500 */
  }
}

.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}
</style>