import { AppRouteRecord } from '../types';

const authRoutes: AppRouteRecord[] = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/pages/LoginPage.vue')
  },
  {
    path: '/signup',
    name: 'Signup',
    component: () => import('@/pages/SignupPage.vue')
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import('@/pages/ForgotPasswordPage.vue')
  },
  {
    path: '/verify-email',
    name: 'VerifyEmail',
    component: () => import('@/pages/VerifyEmailPage.vue')
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: () => import('@/pages/ResetPasswordPage.vue')
  },
  {
    path: '/check-your-email',
    name: 'CheckYourEmail',
    component: () => import('@/pages/CheckYourEmailPage.vue')
  },
  {
    path: '/auth/google/callback',
    name: 'GoogleAuthCallback',
    component: () => import('@/pages/GoogleAuthCallback.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/google-calendar-callback',
    name: 'GoogleCalendarCallback',
    component: () => import('@/pages/GoogleCalendarCallback.vue')
  }
];

export default authRoutes; 