import { Module } from 'vuex';
import { API_URL, isDebugMode } from '@/config';
import { RootState, GoogleState } from '../types';

interface GoogleCalendarStatus {
  connected: boolean;
}

const googleModule: Module<GoogleState, RootState> = {
  namespaced: true,

  state: () => ({
    isGoogleCalendarConnected: false,
  }),

  getters: {
    isCalendarConnected: (state: GoogleState): boolean => state.isGoogleCalendarConnected,
  },

  mutations: {
    SET_CALENDAR_CONNECTED(state: GoogleState, value: boolean) {
      state.isGoogleCalendarConnected = value;
    },
  },

  actions: {
    setGoogleCalendarConnected({ commit }, value: boolean) {
      commit('SET_CALENDAR_CONNECTED', value);
    },

    async fetchGoogleCalendarStatus({ commit }): Promise<GoogleCalendarStatus> {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('No authentication token found');
        }

        const response = await fetch(`${API_URL}/google_calendar_callback/status`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'Origin': window.location.origin
          },
          credentials: 'include'
        });

        if (!response.ok) {
          throw new Error('Failed to fetch Google Calendar status');
        }

        const data: GoogleCalendarStatus = await response.json();
        commit('SET_CALENDAR_CONNECTED', data.connected);
        return data;
      } catch (error) {
        if (isDebugMode()) {
          console.error('Error fetching Google Calendar status:', error);
        }
        commit('SET_CALENDAR_CONNECTED', false);
        throw error;
      }
    },
  }
};

export default googleModule; 