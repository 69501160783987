import { onMounted, onUnmounted } from 'vue';
import { useStore } from 'vuex';
import { isDebugMode } from '@/config';
import { handleAuthError } from '@/utils/authErrorHandler';
import { useRouter } from 'vue-router';

export function useTokenRefresh() {
  const store = useStore();
  const router = useRouter();
  let refreshInterval;

  const refreshToken = async () => {
    try {
      const token = store.getters['auth/getToken'];
      if (!token) {
        throw new Error('No authentication token found');
      }
      
      await store.dispatch('auth/initialize');
      if (isDebugMode()) {
        console.log('Token refreshed successfully');
      }
    } catch (error) {
      if (isDebugMode()) {
        console.error('Failed to refresh token:', error);
      }
      await handleAuthError();
    }
  };

  onMounted(() => {
    // Refresh token every 55 minutes (assuming 1-hour expiration)
    refreshInterval = setInterval(refreshToken, 55 * 60 * 1000);
  });

  onUnmounted(() => {
    if (refreshInterval) {
      clearInterval(refreshInterval);
    }
  });
}