import mixpanel from 'mixpanel-browser';

const MIXPANEL_TOKEN = import.meta.env.VITE_APP_MIXPANEL_TOKEN;
const DEBUG_MODE = import.meta.env.DEV || import.meta.env.VITE_DEBUG === 'true';

interface AnalyticsConfig {
  requireCookieConsent?: boolean;
}

interface UserTraits {
  [key: string]: string | number | boolean | null;
}

interface EventProperties {
  [key: string]: string | number | boolean | null;
}

export const initializeAnalytics = ({ requireCookieConsent = true }: AnalyticsConfig = {}): void => {
  if (!MIXPANEL_TOKEN) {
    if (DEBUG_MODE) {
      console.warn('Mixpanel token not found. Analytics will not be initialized.');
    }
    return;
  }

  mixpanel.init(MIXPANEL_TOKEN, {
    debug: DEBUG_MODE,
    ignore_dnt: !requireCookieConsent
  });
};

export const identify = (userId: string, traits: UserTraits = {}): void => {
  if (!MIXPANEL_TOKEN) return;
  mixpanel.identify(userId);
  mixpanel.people.set(traits);
};

export const track = (event: string, properties: EventProperties = {}): void => {
  if (!MIXPANEL_TOKEN) return;
  mixpanel.track(event, properties);
};

export const trackPageView = (pageName: string, properties: EventProperties = {}): void => {
  if (!MIXPANEL_TOKEN) return;
  track('Page View', {
    page: pageName,
    ...properties
  });
}; 