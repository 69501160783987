import { Module } from 'vuex';
import { API_URL } from '@/config';
import { RootState, NotificationState, Notification } from '../types';

interface NotificationItem extends Notification {
  is_read: boolean;
}

const notificationsModule: Module<NotificationState, RootState> = {
  namespaced: true,
  
  state: {
    notifications: []
  },

  mutations: {
    SET_NOTIFICATIONS(state: NotificationState, notifications: NotificationItem[]) {
      console.log('Setting notifications in store:', notifications);
      state.notifications = notifications;
    },

    MARK_AS_READ(state: NotificationState, notificationId: string) {
      const notification = state.notifications.find(n => n.id === notificationId);
      if (notification) {
        (notification as NotificationItem).is_read = true;
      }
    }
  },

  actions: {
    setNotifications({ commit }, notifications: NotificationItem[]) {
      console.log('setNotifications action called with:', notifications);
      commit('SET_NOTIFICATIONS', notifications);
    },

    async markAsRead({ commit }, notificationId: string): Promise<void> {
      try {
        const response = await fetch(`${API_URL}/notifications/${notificationId}`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          },
          body: JSON.stringify({ is_read: true })
        });

        if (!response.ok) throw new Error('Failed to mark notification as read');

        commit('MARK_AS_READ', notificationId);
      } catch (error) {
        console.error('Error marking notification as read:', error);
        throw error;
      }
    }
  }
};

console.log('Notifications module being loaded:', notificationsModule);
export default notificationsModule; 