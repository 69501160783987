type Environment = 'production' | 'staging' | 'local' | 'LOCAL';

export const API_URL: string = (() => {
  const host = window.location.hostname;
  
  // Handle both localhost and staging environments
  if (host.includes('staging')) {
    return 'https://staging-api.justbreathe.com';
  } else if (host === 'localhost' || host === '127.0.0.1') {
    return 'http://localhost:8000';
  }
  return 'https://api.justbreathe.com';
})();

export const isDebugMode = (): boolean => {
  const env = (import.meta.env.VITE_APP_ENV || '').toLowerCase();
  if (env === 'production') {
    return false;
  }
  return import.meta.env.VITE_DEBUG_MODE === 'true' || 
         import.meta.env.MODE === 'development' || 
         window.location.hostname === 'localhost';
};

export const getGoogleClientId = (): string => {
  const env = (import.meta.env.VITE_APP_ENV || 'local').toLowerCase() as Environment;
  switch (env) {
    case 'production':
      return import.meta.env.VITE_APP_GOOGLE_CLIENT_ID_PROD;
    case 'staging':
      return import.meta.env.VITE_APP_GOOGLE_CLIENT_ID_STAGING;
    default:
      return import.meta.env.VITE_APP_GOOGLE_CLIENT_ID_LOCAL;
  }
};

export const getGoogleAuthRedirectUri = (): string => {
  const env = (import.meta.env.VITE_APP_ENV || 'LOCAL') as Environment;
  switch (env) {
    case 'production':
      return 'https://justbreathe.com/google-auth-callback';
    case 'staging':
      return 'https://staging.justbreathe.com/google-auth-callback';
    default:
      return 'http://localhost:8080/google-auth-callback';
  }
};

export const ORIGIN: string = (() => {
  const env = (import.meta.env.VITE_APP_ENV || 'LOCAL') as Environment;
  switch (env) {
    case 'production':
      return 'https://justbreathe.com';
    case 'staging':
      return 'https://staging.justbreathe.com';
    default:
      return 'http://localhost';
  }
})();

export const getGoogleCalendarRedirectUri = (): string => {
  const env = (import.meta.env.VITE_APP_ENV || 'LOCAL') as Environment;
  switch (env) {
    case 'production':
      return 'https://justbreathe.com/google-calendar-callback';
    case 'staging':
      return 'https://staging.justbreathe.com/google-calendar-callback';
    default:
      return 'http://localhost:80/google-calendar-callback';
  }
};

export default {
  getGoogleClientId,
  ORIGIN,
  isDebugMode,
  getGoogleCalendarRedirectUri,
  getGoogleAuthRedirectUri
}; 