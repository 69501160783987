import { ref } from 'vue';
import { isDebugMode } from '@/config';
import { icons } from '@/assets/icons';

const toastQueue = ref([]);

export function useToast() {
  const addToast = (toast) => {
    const id = Date.now();
    toastQueue.value.push({ ...toast, id });
    if (isDebugMode()) {
      console.log('Toast added:', toast);
    }
    if (toast.duration > 0) {
      setTimeout(() => removeToast(id), toast.duration);
    }
  };

  const removeToast = (id) => {
    const index = toastQueue.value.findIndex(toast => toast.id === id);
    if (index !== -1) {
      toastQueue.value.splice(index, 1);
    }
  };

  function showToast(message, type = 'success', options = {}) {
    const {
      duration = 3000,
      showCloseButton = false,
      rewardPoints = null,
      badgeInfo = null,
      customClass = null,
    } = options;

    const icon = icons[type] || null;

    if (isDebugMode()) {
      console.log('Toast options:', { message, type, duration, showCloseButton, rewardPoints, badgeInfo, icon, customClass });
    }

    addToast({
      message,
      type,
      duration,
      showCloseButton,
      rewardPoints,
      badgeInfo,
      icon,
      customClass
    });

    if (isDebugMode()) {
      console.log('Toast shown:', { message, type, options });
    }
  }

  function showPointsToast(message, points, options = {}) {
    showToast(message, 'reward', { 
      ...options, 
      rewardPoints: points
    });
  }

  function showBadgeToast(message, badgeInfo, options = {}) {
    showToast(message, 'badge', { ...options, badgeInfo });
  }

  return {
    showToast,
    showPointsToast,
    showBadgeToast,
    toastQueue
  };
}
