import { AppRouteRecord } from '../types';

const clientRoutes: AppRouteRecord[] = [
  {
    path: '/clients',
    name: 'Clients',
    component: () => import('@/pages/ClientsPage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/clients/:id',
    name: 'ClientDetails',
    component: () => import('@/pages/ClientDetailsPage.vue'),
    meta: { requiresAuth: true }
  }
];

export default clientRoutes; 