<template>
  <div>
    <div class="badge-toast-container">
      <TransitionGroup name="badge-toast-list">
        <ToastNotification
          v-for="toast in badgeToasts"
          :key="toast.id"
          v-bind="toast"
          :icon="toast.icon"
          class="badge-toast-item"
          @close="removeToast(toast.id)"
        />
      </TransitionGroup>
    </div>
    <div class="regular-toast-container">
      <TransitionGroup name="toast-list">
        <ToastNotification
          v-for="toast in regularToasts"
          :key="toast.id"
          v-bind="toast"
          :icon="toast.icon"
          class="toast-item"
          @close="removeToast(toast.id)"
        />
      </TransitionGroup>
    </div>
  </div>
</template>

<script setup>
import { computed, watch, ref } from 'vue';
import ToastNotification from './ToastNotification.vue';
import { API_URL, isDebugMode } from '@/config'
import { useToast } from '@/composables/useToast';

const { toastQueue } = useToast();

const removeToast = (id) => {
  toastQueue.value = toastQueue.value.filter(toast => toast.id !== id);
  
  if (isDebugMode()) {
    console.log('Toast removed from container:', id);
    console.log('Remaining toasts:', toastQueue.value);
  }
};

const badgeToasts = computed(() => {
  return toastQueue.value.filter(toast => toast.type === 'badge');
});

const regularToasts = computed(() => {
  return toastQueue.value.filter(toast => toast.type !== 'badge').slice(-5);
});

// Add this watch to log the toasts when they change
watch(regularToasts, (newToasts) => {
  if (isDebugMode()) {
    console.log('Visible toasts:', newToasts);
    newToasts.forEach(toast => {
      console.log('Toast icon:', toast.icon);
    });
  }
}, { deep: true });
</script>

<style scoped>
.regular-toast-container {
  position: fixed;
  z-index: 9999;
  bottom: 80px;  /* Increased from 60px to 80px */
  right: 40px;
  left: auto;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-end;
  pointer-events: none;
  padding: 0;
  box-sizing: border-box;
}

.badge-toast-container {
  position: fixed;
  z-index: 10000;
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  pointer-events: none;
  box-sizing: border-box;
}

.toast-item, .badge-toast-item {
  pointer-events: auto;
  margin-bottom: 10px;
  max-width: 400px;  /* Added max-width for regular toasts */
  width: 100%;       /* Added width */
}

.toast-list-enter-active,
.toast-list-leave-active,
.badge-toast-list-enter-active,
.badge-toast-list-leave-active {
  transition: all 0.3s ease;
}

.toast-list-enter-from,
.toast-list-leave-to {
  opacity: 0;
  transform: translateX(100%);  /* Changed to slide in from right */
}

.badge-toast-list-enter-from,
.badge-toast-list-leave-to {
  opacity: 0;
  transform: translateY(-30px); /* Already correct */
}

@media (max-width: 767px) {
  .regular-toast-container {
    right: 10px;
    bottom: 60px;   /* Increased from 10px to 60px */
    left: 10px;
  }
  
  .toast-item {
    max-width: none;
  }
}
</style>
