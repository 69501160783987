import api from './api';
import store from '@/store';
import { ref } from 'vue';
import { isDebugMode } from '@/config';
import { User } from '@/store/types';

interface LoginCredentials {
  email: string;
  password: string;
}

interface LoginResponse {
  access_token: string;
  user: User;
}

interface GoogleCredentialResponse {
  credential: string;
}

/**
 * Generates a cryptographically secure random string for auth state
 * @param length - The length of the random string in bytes
 * @returns A hex string of the specified length
 */
export const generateRandomString = (length: number): string => {
  const array = new Uint8Array(length);
  crypto.getRandomValues(array);
  return Array.from(array, byte => byte.toString(16).padStart(2, '0')).join('');
};

/**
 * Store the authentication token in localStorage
 * @param token - The authentication token to store
 */
export const setToken = (token: string): void => {
  localStorage.setItem('token', token);
};

/**
 * Retrieve the authentication token from localStorage
 * @returns The stored token or null if not found
 */
export const getToken = (): string | null => {
  return localStorage.getItem('token');
};

/**
 * Remove the authentication token from localStorage
 */
export const removeToken = (): void => {
  localStorage.removeItem('token');
};

export const login = async (credentials: LoginCredentials): Promise<User> => {
  const formData = new URLSearchParams();
  formData.append('username', credentials.email);
  formData.append('password', credentials.password);

  const response = await api.post<LoginResponse>('/auth/login', formData, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });

  const { access_token, user } = response.data;
  localStorage.setItem('token', access_token);
  store.commit('auth/setUser', {
    ...user,
    total_system_points: user.total_system_points || 0
  });
  return user;
};

export const logout = async (): Promise<void> => {
  const token = localStorage.getItem('token');
  if (token) {
    try {
      await api.post('/auth/logout', {}, {
        headers: { Authorization: `Bearer ${token}` }
      });
    } catch (error) {
      console.error('Error during logout API call:', error);
    }
  } else {
    console.log('No token found, skipping logout API call');
  }
  localStorage.removeItem('token');
};

export const refreshToken = async (): Promise<string> => {
  const response = await api.post<{ access_token: string }>('/auth/refresh');
  const { access_token } = response.data;
  localStorage.setItem('token', access_token);
  return access_token;
};

export const handleSessionExpiration = (): void => {
  store.dispatch('auth/logout');
};

const handleGoogleSignInError = (error: Error): void => {
  if (isDebugMode()) {
    console.error('Google Sign-In Error:', error);
  }
};

export async function handleCredentialResponse(response: GoogleCredentialResponse): Promise<void> {
  try {
    if (!response.credential) {
      throw new Error('No credential received');
    }

    const result = await store.dispatch('auth/googleLogin', { 
      credential: response.credential 
    });
    
    if (!result.success) {
      throw new Error('Authentication failed');
    }
  } catch (error) {
    if (error instanceof Error) {
      handleGoogleSignInError(error);
    } else {
      handleGoogleSignInError(new Error('Unknown error occurred'));
    }
  }
}

export const handleAuthError = (): void => {
  if (isDebugMode()) {
    console.log('Auth error detected');
  }
  
  // Clear auth state
  localStorage.removeItem('token');
  localStorage.removeItem('user');
  
  // Update store
  store.commit('auth/setUser', null);
  store.commit('auth/setAuthenticated', false);
}; 