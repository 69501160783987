import JSConfetti from 'js-confetti';
import { ref, onMounted } from 'vue';
import { isDebugMode } from '@/config';

interface ConfettiConfig {
  emojis?: string[];
  emojiSize?: number;
  confettiNumber?: number;
  confettiColors?: string[];
}

type ConfettiType = 'default' | 'celebration' | 'success' | 'reward';

export function useConfetti() {
  const jsConfetti = ref<JSConfetti | null>(null);

  onMounted(() => {
    jsConfetti.value = new JSConfetti();
  });

  const confettiTypes: Record<ConfettiType, ConfettiConfig> = {
    default: {
      emojis: ['✨'],
      emojiSize: 25,
      confettiNumber: 100,
    },
    celebration: {
      emojis: ['🎉', '🎊', '🍾', '🎈'],
      emojiSize: 25,
      confettiNumber: 100,
    },
    success: {
      confettiColors: ['#00ff00', '#4CAF50', '#45a049', '#66bb6a'],      
      confettiNumber: 100,
    },
    reward: {
      emojis: ['⭐️', '🥇', '🌟'],
      emojiSize: 25,
      confettiNumber: 100,
    },
  };

  const showConfetti = (type: ConfettiType = 'default'): boolean => {
    if (jsConfetti.value) {
      const config = confettiTypes[type] || confettiTypes.default;
      jsConfetti.value.addConfetti(config);
      if (isDebugMode()) {
        console.log(`Confetti launched with type: ${type}`);
      }
      return true;
    } else {
      if (isDebugMode()) {
        console.error('JSConfetti not initialized');
      }
      return false;
    }
  };

  return {
    showConfetti,
    confettiTypes: Object.keys(confettiTypes) as ConfettiType[],
  };
} 