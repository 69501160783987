import { AppRouteRecord } from '../types';
import authRoutes from './auth.routes';
import serviceRoutes from './service.routes';
import clientRoutes from './client.routes';
import settingRoutes from './setting.routes';
import publicRoutes from './public.routes';

const routes: AppRouteRecord[] = [
  ...authRoutes,
  ...serviceRoutes,
  ...clientRoutes,
  ...settingRoutes,
  ...publicRoutes
];

export default routes; 