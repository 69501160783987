<template>
  <nav class="bg-white shadow-md sticky top-0 z-50">
    <div class="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
      <div class="relative flex items-center justify-between h-16">
        <div class="absolute inset-y-0 left-0 flex items-center sm:hidden">
          <button
            class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
            aria-controls="mobile-menu"
            :aria-expanded="menuOpen"
            @click.stop="toggleMenu"
          >
            <span class="sr-only">{{ menuOpen ? 'Close main menu' : 'Open main menu' }}</span>
            <svg
              :class="{'hidden': menuOpen, 'block': !menuOpen }"
              class="h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 6h16M4 12h16m-7 6h7"
              />
            </svg>
            <svg
              :class="{'block': menuOpen, 'hidden': !menuOpen }"
              class="h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <div class="flex-1 flex items-center justify-center sm:items-center sm:justify-start">
          <div class="flex-shrink-0 flex items-center">
            <router-link 
              to="/" 
              class="logo-text"
              aria-label="Go to Home Page"
            >
              just breathe
            </router-link>
          </div>
          <!-- Show navigation links only when initialized and not loading -->
          <div v-if="isInitialized && !isLoading" class="hidden sm:block sm:ml-6">
            <div class="flex space-x-4 h-full items-center">
              <router-link
                v-for="link in filteredNavLinks"
                :key="link.to"
                :to="link.to"
                class="text-gray-900 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                :class="{ 'bg-gray-900 text-white': $route.path === link.to }"
              >
                {{ link.text }}
              </router-link>
            </div>
          </div>
          <!-- Show loading spinner when loading -->
          <div v-if="isLoading" class="flex items-center ml-4">
            <div class="animate-spin rounded-full h-5 w-5 border-b-2 border-indigo-500"></div>
          </div>
          <div class="absolute inset-y-0 right-0 flex items-center pr-2">
            <!-- Show auth buttons only when initialized and not loading -->
            <template v-if="isInitialized && !isLoading">
              <template v-if="isAuthenticated">
                <button
                  @click="handleLogout"
                  class="text-gray-900 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                >
                  Logout
                </button>
              </template>
              <template v-else>
                <router-link
                  to="/login"
                  class="text-gray-900 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                >
                  Login
                </router-link>
              </template>
            </template>
          </div>
        </div>
      </div>
      <transition
        enter-active-class="transition ease-out duration-100 transform"
        enter-from-class="opacity-0 scale-95"
        enter-to-class="opacity-100 scale-100"
        leave-active-class="transition ease-in duration-75 transform"
        leave-from-class="opacity-100 scale-100"
        leave-to-class="opacity-0 scale-95"
      >
        <div
          v-show="menuOpen"
          id="mobile-menu"
          ref="mobileMenuRef"
          class="sm:hidden absolute w-full left-0 bg-white shadow-lg divide-y divide-gray-100"
          style="top: 100%"
        >
          <div class="py-2">
            <router-link
              v-for="link in filteredNavLinks"
              :key="link.to"
              :to="link.to"
              class="block px-4 py-3 text-gray-900 hover:bg-blue-600 hover:text-white text-base font-medium transition-colors duration-200"
              :class="{ 'bg-blue-50 text-blue-500 border-l-4 border-blue-500': $route.path === link.to }"
              @click.stop="closeMenu"
            >
              {{ link.text }}
            </router-link>
          </div>
        </div>
      </transition>
    </div>
  </nav>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { track } from '@/utils/analytics'
import { eventBus, MENU_EVENT } from '@/utils/eventBus'

const store = useStore()
const router = useRouter()

// Auth state from store
const isAuthenticated = computed(() => store.getters['auth/isAuthenticated'])
const isLoading = computed(() => store.getters['auth/isLoading'])
const isInitialized = computed(() => store.getters['auth/isInitialized'])

// Menu state
const menuOpen = ref(false)
const mobileMenuRef = ref(null)

// Menu handlers
const toggleMenu = () => {
  menuOpen.value = !menuOpen.value
  eventBus.emit(MENU_EVENT)
}

const closeMenu = () => {
  if (menuOpen.value) {
    menuOpen.value = false
  }
}

const handleOutsideClick = (event) => {
  if (menuOpen.value && mobileMenuRef.value && !mobileMenuRef.value.contains(event.target)) {
    closeMenu()
  }
}

// Navigation links
const navLinks = [
  { to: '/', text: 'Home', hideWhenLoggedIn: false },
  { to: '/about', text: 'About', hideWhenLoggedIn: true },
  { to: '/browse', text: 'Browse', hideWhenLoggedIn: true },
  { to: '/releases', text: 'Releases', hideWhenLoggedIn: true },
  { to: '/services', text: 'Services', requiresAuth: true },
  { to: '/clients', text: 'Clients', requiresAuth: true },        
  { to: '/email-templates', text: 'Emails', requiresAuth: true },
  { to: '/forms', text: 'Forms', requiresAuth: true },
  { to: '/settings', text: 'Settings', requiresAuth: true }
]

const filteredNavLinks = computed(() => {
  // Don't show auth-dependent links during initialization
  if (!isInitialized.value) {
    return navLinks.filter(link => link.text === 'Home' || (!link.requiresAuth && !link.hideWhenLoggedIn && !link.showOnlyWhenInitialized))
  }
  
  if (!isAuthenticated.value) {
    return navLinks.filter(link => {
      if (link.text === 'Home') return true
      if (link.requiresAuth) return false
      if (link.showOnlyWhenInitialized && !isInitialized.value) return false
      return true
    })
  }
  
  return navLinks.filter(link => {
    if (link.text === 'Home') return true
    if (link.requiresAuth && !isAuthenticated.value) return false
    if (link.hideWhenLoggedIn && isAuthenticated.value) return false
    return true
  })
})

// Methods
const handleLogout = async () => {
  try {
    await store.dispatch('auth/logout')
    router.push('/login')
  } catch (error) {
    console.error('Logout failed:', error)
  }
}

// Lifecycle hooks
onMounted(async () => {
  document.addEventListener('click', handleOutsideClick)
  if (!isInitialized.value) {
    await store.dispatch('auth/initialize')
  }
})

onUnmounted(() => {
  document.removeEventListener('click', handleOutsideClick)
})
</script>

<style scoped>
@font-face {
  font-family: 'acroScript';
  src: url('@/assets/fonts/acroScript.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

.logo-text {
  font-family: 'acroScript', cursive;
  font-size: 32px;
  color: #3A4A4A;
  text-align: center;
  font-weight: 400;
  line-height: 64px;
  width: 181px;
  display: block;
}

#mobile-menu {
  z-index: 40;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  max-height: calc(100vh - 64px);
  overflow-y: auto;
  background-color: #ffffff;
}

@media (max-width: 640px) {
  #mobile-menu a {
    min-height: 48px;
    -webkit-tap-highlight-color: transparent;
  }
  
  #mobile-menu a:active {
    background-color: rgba(0, 0, 0, 0.05);
  }
}
</style>
